.sections-control-paper {
  margin-top: 50px;
  padding: 60px;

  .edit-box {
    padding: 30px 10px;
    margin: 0px 4px 10px 4px;
    border: 0.7px solid #cfd8dc;
  }

  .title {
      font-size: 20px;
      text-align: center;
      color: #7cb342;
      padding-bottom: 10px;
      border-bottom: 1.5px solid #7cb342;
      width: 100%;
    }

  .colors-title {
      text-align: left;
    }


  .edit-title {
    color: #818181;
  }

  .list-button {
      width: 100%;
      background: 0;
      border: 0;
      padding: 10px;
      margin-bottom: 10px;
      background: #eeeeee;
      cursor: pointer;

      &:hover {
        color:#7da453;
      }
  }

  .delete-button {
    width: 100%;
    background: 0;
    border: 0;
    padding: 7px;
    margin-bottom: 10px;
    background: #eeeeee;
    cursor: pointer;

    &:hover {
      color: white;
      background: #b61827;
    }
  }


.edit-button {
  width: 100%;
  background: 0;
  border: 0;
  padding: 7px;
  margin-bottom: 10px;
  align-content: center;
  justify-content: center;
  background: #eeeeee;
  cursor: pointer;

  &:hover {
    color: white;
    background: #eeaf00;
  }
}

.done-button {
  width: 100%;
  background: 0;
  border: 0;
  padding: 7px;
  margin-bottom: 10px;
  align-content: center;
  justify-content: center;
  background: #eeeeee;
  cursor: pointer;

  &:hover {
    color: white;
    background: #7cb342;
  }
}

.selected-list-button {
    width: 100%;
      background: 0;
      border: 0;
      padding: 10px;
      margin-bottom: 10px;
      background: #7cb342;
      color: white;
      cursor: pointer;
}

  .add-button {
    color: white;
    background: #558b2f;
    transition: all 0.2s ease-in;
    border: 0;
    border-radius: 5px;
    height: 40px;
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: #33691e;
    }
  }

  .sections-control-input {
  width: 100%;
  height: 44px;
  margin-bottom: 10px;
  padding-left: 10px;
  border-radius: 4px;
  border: 0.7px solid #cfd8dc;
  outline: 0;
}
}
