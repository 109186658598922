.card-root {
  margin-top: 20px;

  .MuiCard-root {
    overflow: visible;
}

  .data-entry-input {
    margin-top: 15px;
    width: 210px;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
    min-height: 40px;
  }

  .data-entry-color {
    margin-top: 15px;
    min-height: 40px;
    max-width: 210px;
  }
  .card-max-height{
    max-height: 218px;
    overflow-y: auto;
  }
  .date-card-height{
    max-height: 489px;
    overflow-y: auto;
  }
  .card-manager{
    padding: 10px;
    .rotate{
      transform: rotate(180deg); 

    }
  }
  .card-center{
    padding-bottom: 13px;
  }
  .min-height-card{
    min-height: 362px;
  }
  .content-container{
    min-height: 130px;
  }

}


@media (min-width: 1281px) {
  .card-center{
        min-width: 320px; 
     }
}

@media (max-width: 1280px) and (min-width: 1025px){
.card-center {
    min-width: 364px;
  }
}
@media (min-width: 581px) and (max-width: 1024px) {
  .card-center{
    max-width: 325px; 
  }
}


@media (min-width: 481px) and (max-width: 767px) {
  .text-align{
    text-align: center;
  }
  .card-center{
    max-width: 320px; 
  }
  
}

@media (min-width: 320px) and (max-width: 480px) {
  .card-center{
    max-width: 280px; 
  }
}

//For Manager Page Cards
@media (min-width: 375px) and (max-width: 470px) {
  .card-manager{
    min-width: 300px; 
  }
}

@media (min-width: 300px) and (max-width: 891px) {
  .card-manager{
    min-width: 250px;
  }
}
