.table-root {
  width: 100%;
  margin-top: 20px;

  .table-wrapper {
    overflow-x: auto;
  }

  .status-active {
    background: #aed581;
  }

  .status-inactive {
    background: #c5cae9;
  }

  .status-sent {
    background: #cce9f7;
  }

  .status-photoenriched {
    background: #ffe082;
  }

  .status-dataupdated {
    background: #f8bbd0;
  }

}

  .table-toolbar {
    .table-spacer {
      flex: 1 1 100%;
    }

    .table-title {
      flex: 0 0 auto;
    }

    .sent {
      display: flex;
      color: #7cb342;
      font-weight: 500;
      margin-top: 8px;

      .sent-icon {
        margin-left: 8px;
      }
    }
  }


.table-button {
  padding: 6px 23px;
  background: #558b2f;
  margin: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
  height: 38px;
  width: 100%;
}

.table-button:hover {
  background: #33691e;
  cursor: pointer;
}

.toolbar-buttons {
  display: flex;
  width: 100%;
  margin: 20px;
}

.sizes-cell {
  display: flex;
  line-height: 100%;
}

.sizes-p {
  margin-left: 8px;
}

.colors-cell {
  display: flex;
  padding-left: 5ps;
}

.sent-quantity-cell {
  display: flex;

  span{
    padding-right: 5px;
  }
}

.descreption-cell {
  width: 300px;
}

.table-cell {
  .edit-button {
    background: 0;
    border: 0;
  }

  .edit-icon {
    width: 16px;
    cursor: pointer;
  }

  .quantity-cell {
    display: flex;
    justify-content: space-between;
    
    span{
      padding-right: 5px;
    }
  }

  .prices-cell {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
    width: 140px;

    .price-input {
      text-align: right;
      width: 50px;
      height: 18px;
    }
  }
}

.section-cell{
  margin-bottom: 7px;
}
