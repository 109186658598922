.designer-search-paper {
  margin: 20px 0px;
  padding: 30px;

  .title {
    font-size: 20px;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
    width: 100%;
  }

  .download-text {
    text-align: center;
    margin: 10px;
  }

  .download-button {
    color: white;
    background: #558b2f;
    transition: all 0.2s ease-in;
    border: 0;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background: #33691e;
    }
  }

  .padding-header{
    padding: 5px;
  }
  .break{
    margin-top: 100px;
  }
}
