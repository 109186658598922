.file-uploader {
  width: 100%;
  // margin-top: -106px;
}

.file-container {
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-in;
}

.file-container input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.file-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.file-container p {
  font-size: 12px;
  margin: 8px 0 4px;
}

.file-container .errors-container {
  max-width: 300px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.file-container .choose-file-button {
  background: 0;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 10px;
  margin-bottom: 20px;
}

.file-container .upload-files-button {
  background: transparent;
  border-radius: 30px;
  color: #3f4257;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 0;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: 1px solid #3f4257;
}

.file-container .upload-files-button:hover {
  background: #3f4257;
  color: #fff;
}

.file-container .upload-icon {
  width: 50px;
  height: 50px;
}

.file-container .uupload-picture-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.file-container .upload-picture-container {
  width: 180px;
  margin: 20px;
  padding: 6px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
}

.file-container .upload-picture-container img.uploadPicture {
  width: 100%;
}

.file-container .delete-button {
  position: absolute;
  top: -9px;
  right: -9px;
  color: #fff;
  background: gray;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
  line-height: 12px;
  width: 25px;
  height: 25px;
  border: none;
  padding: 4px;
}

.flip-move {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

#coverimage , #mainImage , #menuImage{
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
