.transportation-container {
    overflow: hidden;
    max-height: 1500px;
  
    .cover-photo{
      width: 100%;
      height: 100%;
      margin-top: -40px;
      border-radius: 5px 5px 0px 0px;
      object-fit: cover;
      background: grey;
    }
  }
  
  .upload-button-wrapper {
    position: relative;
    width: 120px;
    text-align: center;
  
    span.label {
      position: relative;
      z-index: 0;
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 10px 0;
      text-transform:uppercase;
      font-size:12px;
    }
  
    #CoverPhoto {
        display: inline-block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 50px;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }
  }
  
  .done {
    display: flex;
    color: #7cb342;
    font-weight: 500;
    margin-top: 8px;
  
    .done-icon {
      margin-left: 8px;
    }
  }