.dialogContet {
	min-height: 300px;
	margin-top: 10px;
	.margin-section {
		margin-top: 5px;
	}
	.marginColor {
		margin-top: 25px;
		.sectionwidth {
			width: 191px
		}
	}
	.marginTags {
		margin-top: 25px;
	}
	.marginDesc {
		margin-top: 30px;
	}
	.arabicFields {
		direction: rtl
	}
	.Description-textarea {
		width: 100%;
		min-height: 100px;
		padding: 0px 10px;
		border-radius: 4px;
		border: 0.7px solid #cfd8dc;
	}
	.button-text {
		color: white;
	}
	.ImageGallaryGrid {
		margin-top: 40px
	}
	.image-gallery-slide img {
		width: 100%;
		border-radius: 20%;
		overflow: hidden;
		border: 1px solid;
		border-radius: 10%;
		overflow: hidden;
		max-height: 950px;
		box-shadow: 0px 0px 0px 1px #ffffff inset;
	}
	@media (min-width: 320px) and (max-width: 480px) {
		.ImageGallaryGrid {
			min-width: 500px;
		}
		.card-input {
			min-width: 500px;
		}
	}
	@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
		.image-gallery-slide img {
			width: 100%;
			border-radius: 20%;
			overflow: hidden;
			border: 1px solid;
			border-radius: 10%;
			overflow: hidden;
			max-height: 600px;
			box-shadow: 0px 0px 0px 1px #ffffff inset;
		}
	}
}
