
.navbar-root {
  box-sizing: border-box;
  flex-grow: 1;
  background: #f9f9f9;

  .Rawabi-logo {
    margin-left: 20px;
    width: 50px;
    margin: 12px 12px 12px 40px;
  }
  .hor-navbar {
    margin-left: 100px;
    display: flex;
    flex-direction: row;
    width: 100%;
  .hor-navbar-content {
      flex: 1;
    }
  }
}

.navbar-list {
   box-sizing: border-box;
  width: 250px;

  .navbar-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
  }
}

.menu-button {
  float: right;
}

.navbar-link:hover {
  text-decoration: none;
}

.horNavbar-link {
  color: gray;
  margin-left: 10px;
}

.horNavbar-link:hover {
  text-decoration: none;
  color: black;
  background: darkolivegreen;
  color: white;
  border-radius: 2px;
}

.app-bar {
  background-color: #212121;
}

.dropdown{
  position: absolute;
  top: 24px;
  right:120px;
}

.logout{
  position: absolute;
  right: 55px;
  top:24px;
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  {
    .dropdown{
      position: absolute;
      top: 36px;
      right:40px;
    }
}

@media only screen and (min-width:1167){
  .dropdown{
    position: absolute;
    top: 36px;
    right:100px;
  }
}

@media only screen 
    and (min-width : 375px) 
    and (max-width : 812px) 
    and (-webkit-device-pixel-ratio : 3) {
      .dropdown{
        position: absolute;
        top: 22px;
        right: 75px;
      }
     }
     .active-link{
       font-weight: 500;
       border-radius: 4px;
       border: 2px solid darkolivegreen;
       padding: 2px;
       box-shadow: 1px 3px burlywood;
     }