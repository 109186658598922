.container-root{
  margin-top: 20px;

    .rotate{
        transform: rotate(180deg); 
    }
    .card-action{
      float: right;
      margin-top: 9px;
    }
    .card-width{
      padding: 20px;
    }
    .padding-inputs{
      padding: 3px;
    }
   
}


.update-image-button{
  width: 30px;
  text-align: center
  }
  
  .upload-button-wrapper {
    display: inline-block;
    position: relative;
    width: 120px;
    text-align: center;
    margin-left: 19px;
    span.label {
      position: relative;
      z-index: 0;
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 10px 0;
      text-transform:uppercase;
      font-size:12px;
    }
    #recepietImg {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }
  
  }
  .view-image-container{
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 20px;
    height: auto;
    background:#f4f4f4;
  }
  .image-container{
   
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
 .image-rotate{
   position: absolute;
   top: 200px;
   right: 20px;
   cursor: pointer;
   color: darkblue;
 }
 
@media (min-width: 1025px) and (max-width: 1280px) {
  .card-width{
    min-width: 380px; 
  } 
}

@media (min-width: 581px) and (max-width: 1024px) {
  .card-width{
    min-width: 358px; 
  }
}

@media (min-width: 481px) and (max-width: 767px) {
 
  .card-width{
    max-width: 320px; 
  }
  .text-align{
    text-align: center;
  }
  
}

.image-rotate-left{
  position: absolute;
  cursor: pointer ;
  top: 200px;
  left: 20px;
}

.container-outer {
  margin: auto;
  padding: 5px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
    width: 180px;
     height: 60px; 
     border: 1px solid darkgrey;
     border-radius: 5px;
  }
.container-inner { display: inline-block;}

.barcode-icon {
  height: 170px;
  display: block;
  margin: 0 auto;
}

.barcode-input {
  width: 100%;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  border: 0.7px solid #cfd8dc;
}

.barcode-input::placeholder {
  color: grey;
  font-size: 16px;
  line-height: 30px;
}

.item-imagee:hover{
  transform: scale(1.2) !important;
}
