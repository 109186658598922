.stores-paper {
  margin-top: 20px;
  padding: 30px;

  .title {
    font-size: 20px;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
    width: 100%;
  }

}
