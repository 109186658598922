.login-form {
  .login-input {
    width: 100%;
    height: 38px;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;

    &::placeholder {
      line-height: 30px;
    }
  }

  .login-icon {
    margin: 8px;
  }

  .login-error {
    font-size: 12px;
    margin-left: 44px;
  }

  .login-failed {
    color: red;
    margin-bottom: 20px;
  }

  .login-button {
    color: white;
    background: #558b2f;
    transition: all 0.2s ease-in;
    border: 0;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    font-size: 14px;

    &:hover {
      background: #33691e;
      cursor: pointer;
    }

    &:disabled {
      background: gray;
      cursor: default;
    }
  }
}
