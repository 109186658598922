.container-root{
  // margin-top: 20px;

    .rotate{
        transform: rotate(180deg); 
    }
    .card-action{
      float: right;
      margin-top: 9px;
    }
    .card-width{
      padding: 20px;
    }
    .padding-inputs{
      padding: 3px;
    }
 
   
  .upload-button-wrapper {
    position: relative;
    width: 120px;
    text-align: center;
    margin-left: 19px;
  
    span.label {
      position: relative;
      z-index: 0;
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 10px 0;
      text-transform:uppercase;
      font-size:12px;
    }
    #recepietImg {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }

  }
}


@media (min-width: 1025px) and (max-width: 1280px) {
  .card-width{
    min-width: 380px; 
  } 
}

@media (min-width: 581px) and (max-width: 1024px) {
  .card-width{
    min-width: 358px; 
  }
}

@media (min-width: 481px) and (max-width: 767px) {
 
  .card-width{
    max-width: 320px; 
  }
  .text-align{
    text-align: center;
  }
  
}
