.login-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: #212121;

  .login-paper {
    padding: 30px;
    width: 300px;
  }

  .login-image {
    height: 140px;
    margin-bottom: 30px;
  }
}
