.logs-container {
  font-size: 12px;
  
  .Pass{
    margin: 4px;
    padding: 8px;
    color: white;
    background: #7cb342;
  }

  .NA{
    margin: 4px;
    padding: 8px;
    color: white;
    background: darkgray;
  }

  .InMinus{
    margin: 4px;
    padding: 8px;
    color: white;
    background: #d31111;
  }

  .time{
    color: lightgrey;
  }

}
