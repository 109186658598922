.upload-scan-paper {
  margin-top: 20px;
  padding: 30px;

  .title {
    font-size: 20px;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
    width: 100%;
  }
  .uploadTab-content{
    min-width: 400px;
  }

  .barcode-icon {
    height: 170px;
    display: block;
    margin: 0 auto;
  }

  .barcode-input {
    width: 100%;
    height: 35px;
    text-align: center;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
  }

  .barcode-input::placeholder {
    color: grey;
    font-size: 16px;
    line-height: 30px;
  }

  .error-message {
    text-align: center;
    color: red;
    font-weight: 500;
  }
}


.update-details-paper {
  margin-top: 20px;
  padding: 30px;

  .color-input {
    width: 100%;
    height: 35px;
    text-align: center;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
  }

  .description-input {
    width: 100%;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
  }

  .padding{
    padding: 20px;
    min-width: 320px;
  }
  
  .update-button {
    width: 100%;
    padding: 8px;
    background: #558b2f;
    border-radius: 5px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    transition: all 0.2s ease-in;
    outline: none;
    cursor: pointer;
    border: none;
    &:hover {
      background: #33691e;
    }
  }

  .title {
    font-size: 20px;
    text-align: center;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
  }

  .done {
    display: flex;
    color: #7cb342;
    font-weight: 500;
    margin-top: 8px;

    .done-icon {
      margin-left: 8px;
    }
  }
}

.upload-images-paper {
  margin-top: 20px;
  padding: 30px;

  .title {
    font-size: 20px;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
    width: 100%;
  }

  .color-input {
    width: 100%;
    height: 35px;
    text-align: center;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
  }

  .upload-picture-container {
    width: 180px;
    margin: 20px;
    padding: 6px;
    background: #edf2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #d0dbe4;
    position: relative;
  }

  .delete-button {
    position: absolute;
    top: -9px;
    right: -9px;
    color: #fff;
    background: gray;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    line-height: 12px;
    width: 25px;
    height: 25px;
    border: none;
    padding: 4px;
  }

  img.uploadPicture {
    width: 100%;
  }

  .upload-picture-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .upload-button {
    width: 100%;
    padding: 6px 23px;
    background: #558b2f;
    border-radius: 5px;
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin: 10px 0;
    transition: all 0.2s ease-in;
    outline: none;
    cursor: pointer;
    border: none;
    &:hover {
      background: #33691e;
    }
  }

  .uploading {
    display: flex;

    .uploading-text {
      color: #7cb342;
      font-weight: 500;
      padding: 10px;
    }
    .uploading-icon {
      margin-left: 8px;
    }
  }
}
.synk-button{
  position: absolute;
  bottom: 3px;;
}

.image-rotate{
position: absolute;
top:15px;
right:10px;
color: black !important;
}