.modal {
    font-size: 12px;
  }

  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    // text-align: center;
    padding: 5px;
  }

  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }

  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    // text-align: center;
  }
  
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .popup-content{
  
    // width: 500px;
    margin-bottom: 20px !important;
  .popup-modal {
    margin-left: 0px;
    margin-right: 11px;
    height: auto;
    .popup-title {
      font-size: 16pt;
      font-weight: bold;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    .popup-body {
      // text-align: center;
      list-style-type: none;
      width: fit-content;
    }
  }
}

// .popup-content {
//  width: 500px !important;
// }