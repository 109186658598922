.page-root{
    margin-top: 20px;
    
.cover-container {
    max-height: 1500px;
    margin-bottom: 20px;
    position: relative;

    .cover-photo{
      width: 100%;
      height: 450px;
      border-radius: 5px 5px 0px 0px;
      object-fit: cover;
      background: grey;
    }

    .upload-button-wrapper {
      position: relative;
      width: 120px;
      text-align: center;
      left: 20px;
    
      span.label {
        position: relative;
        z-index: 0;
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 10px 0;
        text-transform:uppercase;
        font-size:12px;
      }
    
      #CoverPhoto {
          display: inline-block;
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 50px;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
      }

    }

  }

  .upload-button-wrapperr {
    position: relative;
    width: 120px;
    text-align: center;
  
    span.label {
      position: relative;
      z-index: 0;
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 10px 0;
      text-transform:uppercase;
      font-size:12px;
    }
  
    #GameImage {
        display: inline-block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 50px;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }
  }

  .search-input {
    width: 100%;
    height: 38px;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc
}

  .search-input::placeholder {
    line-height:30px;
  }

  .margin-button{
    margin-bottom: 21px;
  }

  .Description-textarea {
    width: 100%;
    min-height: 100px;
    padding: 0px 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
  }

  .margin-top{
      margin-top: 5px;
  }

  .date-card-height{
      min-height: 300px;
      max-height: 300px;
      overflow-y: auto;
  }

}

.photo-errors {
  color: red;
  font-size: 12px;
}

.done {
  display: flex;
  color: #7cb342;
  font-weight: 500;
  margin-top: 8px;

  .done-icon {
    margin-left: 8px;
  }
}



