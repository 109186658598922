@import 'node_modules/react-image-gallery/styles/scss/image-gallery.scss';

$font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

body {
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overflow-x: hidden;
  overflow: auto;
}

.rawabi-input {
  width: 100%;
  height: 38px;
  padding: 0px 10px;
  margin-bottom: 6px;
  border-radius: 4px;
  border: 0.7px solid #cfd8dc;
}

.button-text {
  color: white;
  font-weight: 400;
}

.title {
  font-size: 20px;
  color: #7cb342;
  padding-bottom: 10px;
  border-bottom: 1.5px solid #7cb342;
  width: 100%;
}

.Toastify__toast-container {
  width: auto;
  text-align: center;
}
.Toastify__toast--success {
  background: #8BC34A;
}
.toast-info{
  display: flex;
  align-items: center;
}