.info-box {
  padding: 40px;

  .message {
    font-size: 20px;
    text-align: center;
    color: gray;
    padding: 10px;
  }
}
