.create-user-paper {
  padding: 30px;
  min-height: 400px;

  .title {
    font-size: 20px;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
    width: 100%;
  }
  .padding{
    padding: 10px;
  }

  .create-user-input {
    width: 100%;
    height: 38px;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc
  }

  .create-user-input::placeholder {
    line-height:30px;
  }

  .create-user-button {
    color: white;
    background: #558b2f;
    transition: all 0.2s ease-in;
    border: 0;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: #33691e;
    }
  }

  .error {
    font-size: 12px;
    color: red;
  }

}
