@mixin input-button {
  span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 12px;
  }

  #upload {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50px;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
  }
}

.cover-container {

  .cover-img{
    width: 100%;
    height: 450px;
    border-radius: 5px 5px 0px 0px;
    object-fit: cover;
    background: grey;
  }

  .cover-button-wrapper:hover {
    
  }

  .cover-button-wrapper {
    position: relative;
    width: 120px;
    text-align: center;
    bottom: 79px;
    right: -82%;
    background-color: #f5f5f5;
    border-radius: 25px;
    opacity: 0.8;
  
    @include input-button()
  }
}

.profile-container{
  margin-top: -160px;

  .profile-photo{
    width: 260px;
    min-height: 260px;
    border-radius: 50%;
    border: 3px white solid;
    box-shadow: 1px 2px 1px grey;
    margin: 40px;
    background: grey;
  }

  .logo-button-wrapper {
    position: relative;
    width: 120px;
    text-align: center;
    top: -105px;
    left: 105px;
    background-color: #f5f5f5;
    opacity: 0.8;
    border-radius: 50px;
    @include input-button()
  }
}

.menu-photo {
  margin: 5px auto;
  padding: 50px;
  width: 80%;
  height: 80%
}

.restaurant-textarea {
  width: 100%;
  min-height: 100px;
  padding: 0px 10px;
  border-radius: 4px;
  border: 0.7px solid #cfd8dc;
}

.photo-errors {
  color: red;
  font-size: 12px;
}

.done {
  display: flex;
  color: #7cb342;
  font-weight: 500;
  margin-top: 8px;

  .done-icon {
    margin-left: 8px;
  }
}

.margin-bottom{
  margin-bottom: 20px;
}