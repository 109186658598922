.manager-paper {
    margin-top: 50px;
    padding: 40px;

  .manager-input {
  width: 100%;
  height: 38px;
  padding-left: 10px;
  border-radius: 4px;
  border: 0.7px solid #cfd8dc;
  }
  .padding{
    padding: 10px;
  }

  .title {
    font-size: 20px;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
    width: 100%;
  }

  .tagInputContainer {
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;

    .react-tagsinput-tag {
      background-color: #cde69c;
      border-radius: 2px;
      border: 1px solid #a5d24a;
      color: #638421;
      display: inline-block;
      font-family: sans-serif;
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px;
      margin-right: 5px;
      margin-left: 2px;
      margin-top: 2px;
      padding: 5px;
      max-width: 261px;
      overflow-wrap: break-word;
    }

    .react-tagsinput-remove {
      cursor: pointer;
      font-weight: bold;
    }
    
    .react-tagsinput-tag a::before {
      content: ' ×';
    }

    .react-tagsinput-input {
      background: transparent;
      border: 0;
      color: #777;
      border-radius: 2px;
      font-family: sans-serif;
      font-size: 13px;
      font-weight: 400;
      vertical-align: sub;
      margin-bottom: 6px;
      margin-top: 1px;
      margin-left: 2px;
      margin-right: 2px;
      outline: none;
      padding: 5px;
    }
  }

}

