// .Main-paper{
//     margin: 0 auto;
//     width: 90vw;
// }
.table {
    border-collapse: collapse;
    border-radius: 30px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 2px rgb(204, 202, 202); /* this draws the table border  */ 
    overflow-x: scroll !important;
    margin-bottom: 10px;
}
.device-type-container{
    display: flex;
    flex-direction: row;
}

