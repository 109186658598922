.question-container {
  .card {
    font-size: 16px;
    margin: 4px;
    padding: 18px;
    color: white;
    background: #7cb342;
  }

  textarea {
    width: 100%;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
    min-height: 80px;
  }
}
