.date-entry-container {

    padding: 14px;

    .search-input {
        width: 100%;
        height: 38px;
        padding-left: 10px;
        border-radius: 4px;
        border: 0.7px solid #cfd8dc
    }

    .search-input::placeholder {
      line-height:30px;
    }

    .dataentry-button {
        color: white;
        background: #558b2f;
        transition: all 0.2s ease-in;
        border: 0;
        border-radius: 5px;
        height: 38px;
        width: 100%;
        font-size: 14px;
        cursor: pointer;

        &:hover {
        background: #33691e;
      }
    }

    .break {
      position: relative;
      margin: 8px;
    }
    .hasImageSwitch{
      margin-top: 23px;
    }

}



.padding-top{
  padding:4px;
}
.padding-left{
  padding-left: 2px;
}

.button-margin{
  margin-right: 30px;
}

.dialog{
  background-color: #313639;
  color: white;
  text-align: center;
}
.MuiDialogTitle-root{
  width: 100% !important;
}
.confirm-dialog{
  background-color: #313639;
  color: white;
  text-align: center;
}

.MuiDialogContentText-root{
  color: black !important;
  font-size: 20px !important;
}
.slider{
  display: flex;
  justify-content: center;
}