.brand-head {
    position: relative;

    .brand-img {
        width: 100%;
        height: auto;
    }   
}
.brand-name {
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 100%;
    text-align: center;
}

.dialog{
    width: 500px;
  }
.content-container{
    
    .brand-info {
        color: #404040;
        margin-top: 17px;

        .ar-name{
            text-align: right;
        }
    }

    .card-input {
        margin-top: 15px;
        width: 210px;
        padding-left: 10px;
        min-height: 40px;
    }
}

.dat-entry-size{
    align-self: flex-start;
}
.sizequantity-container{
    display: flex;
    align-items: center;
    
}
.dat-entry-Quantity{
    display: flex;
    align-items: center;
    
}
.size{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.7;
}
.price{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.7;
    margin: 20px;
}
.editPrice:hover{
    border: 2px solid #4caf50;
    border-radius: 6px;
}

.cardImage{
    width: 100%;
    height: 160px;
}