.photographer-tab {
    width: 200px;
    font-size: 20px;
    background: rgba(0, 0, 0,0);
    border: rgba(0, 0, 0,0);
    cursor: pointer;
    margin: 10px;
    padding: 10px;
    background: 0;

    &:hover{
      color: #7cb342;
  }
}


.photographer-tab-clicked {
    font-size: 20px;
    color: #7cb342;
    width: 200px;
    margin: 10px;
    padding: 10px;
    border: 0;
    border-bottom: 1.5px solid #7cb342;
    background: 0;
}
