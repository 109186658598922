.barcode-paper {
  padding: 30px;

  .title {
    font-size: 20px;
    color: #7cb342;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #7cb342;
    width: 100%;
  }

  .store {
    font-size: 20px;
    color: #7cb342;
    text-align: center;
  }

  .barcode-icon {
    height: 170px;
    display: block;
    margin: 0 auto;
  }

  .barcode-input {
    width: 100%;
    height: 35px;
    padding-left: 10px;
    margin-bottom: 7px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
  }

  .barcode-input::placeholder {
    color: grey;
    font-size: 16px;
    line-height: 30px;
  }

  .error-message {
    text-align: center;
    color: red;
    font-weight: 500;
  }

  .count {
    text-align: center;
    font-weight: 500;
  }

  .item-status {
    margin-top: 20px;

    .passed {
      display: flex;
      color: #7cb342;
      font-weight: 500;
    }

    .failed {
      display: flex;
      color: #d10c1c;
      font-weight: 500;
    }

    .icon {
      margin-left: 8px;
    }
  }

}
