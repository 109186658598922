.modal {
    font-size: 12px;
  }

  .modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    // text-align: center;
    padding: 5px;
  }

  .modal > .content {
    width: 100%;
    padding: 10px 5px;
  }

  .modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    // text-align: center;
  }
  
  .modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
  }
  .popup-content{
    // width: 500px;
 
  .popup-modal {
    margin-left: 0px;
    margin-right: 11px;
    height: auto;

    .popup-title {
      font-size: 16pt;
      font-weight: bold;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }

    .popup-body {
      // text-align: center;
      list-style-type: none;
      width: fit-content;
    }
  }
}

.popup-content  {
 width: auto !important;
}
.deleteButton{
  cursor: pointer;
  color: red;
}

.width{
  margin-left: 100 !important;
}
.editPrice{
  min-width: 40px;
 
}
.editPrice:hover{
  border: 2px solid #4caf50;
  border-radius: 6px;
}
// .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight{
//   min-width: 190px;
// }

// .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter{
//   min-width: 190px;
// }
.color-filter{
  width: 200px !important;
}
.css-1hwfws3{
  width: 190px;
}