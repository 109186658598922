tr.border_bottom {
    border-bottom:2px solid #8bc34a;
  }

  .item-imagee:hover{
    transform: scale(1.5) !important;
  }
  
  .description-input {
    width: 100%;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
  }