.button-visible{
position: absolute;
right: 10px;
cursor: pointer;
}
.table{
    position: relative;
}

.drawer{
    // background-image:url('../../../assests/images/sidebar.jpg');
    height: 100vh;
   
    // background-attachment: fixed;
    // background-repeat: no-repeat;
    // background-size: contain;;
}
.pieChart{
    box-sizing: border-box;
     width: 20vw;
     display: flex;
     justify-content: center;
     z-index: 2;      
    
       }

       .piechartDetail{
           display: flex;
           align-items: flex-end;
           justify-content: center;
           box-sizing: border-box;
           position: absolute;
           top: 35px;
           left: 13px;
           width: 21vw;
           height: 42vh;
           background: crimson;
           z-index: -7;
       }
       .piechart-container{
           position: relative;
            display: flex;
        
       }
       .linechart-container{
           position: relative;
           box-sizing: border-box;
           display: flex;
           
           
           
       }
       .linechartDetail{
           display: flex;
           width: 23vw;
           height: 39vh;
           position: absolute;
           left: 80px;
           background-color: '#ef5350';
           align-items: center;
       }
       .linechart{
          
        display: flex;
        align-items: flex-end;
        justify-content: center;
        box-sizing: border-box;
        position: absolute;
        top: 33px;
        left: 20px;
        width: 27vw;
        height: 42vh;
        z-index: -7;
       }
     
       .bar-container{
        position: relative;
        display: flex;
        justify-content: center;
       }

       .barchartDetail{

           display: flex;
           width: 25vw;
           background-color: '#ef5350';
           align-items: center;
       }
       

       .charts{
           display: flex;
       }
       .barchart{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        box-sizing: border-box;
        position: absolute;
        top: 34px;
        left: 30px;
        width: 26vw;
        height: 42vh;
        background: crimson;
        z-index: -7;
       }


.header-information{
    display: flex;
    height: 15vh;
    position: relative;
    background-color:#e3e7f1;
    position: relative;
    z-index: 1;
}
.hline{

}
.line{
    width: 100%;
    height: 2px;
    background: black;
    position: absolute;
    bottom: 4vh;
}
.total{
    position: absolute;
    bottom: 1.5vh;
    margin-left: 5px;
}
.total-box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22%;
    height: 55%;
    z-index: 2;
    position: absolute;
    top: -14%;
    left: 8%;
    background-color:#43a047 ;
    border-radius:5px;
    
}
.groupa{
    background-color: rgb(234, 94, 94);
}
.groupb{
    background-color: #ffa726;
}

@media screen and (max-width: 600px) {
     .main-container{
         display: flex;
         justify-content: center;
         flex-direction: column;
        
     }
     .tablecon{
         display: flex;
         flex-direction: column;
     }
     .sales{
         display: flex;
         flex-direction: column;
     }
  }
  .userButton{
      position: absolute;
      
  }
.dash-menu{
   position: absolute;
   right: 16px;
   top: 8px;
   
    
}
 