.container-root{
  // margin-top: 20px;

    .rotate{
        transform: rotate(180deg); 
    }
    .card-action{
      float: right;
      margin-top: 9px;
    }
    .card-width{
      padding: 20px;
    }
    .padding-inputs{
      padding: 3px;
    }
   
}


@media (min-width: 1025px) and (max-width: 1280px) {
  .card-width{
    min-width: 380px; 
  } 
}

@media (min-width: 581px) and (max-width: 1024px) {
  .card-width{
    min-width: 358px; 
  }
}

@media (min-width: 481px) and (max-width: 767px) {
 
  .card-width{
    max-width: 320px; 
  }
  .text-align{
    text-align: center;
  }
  
}
