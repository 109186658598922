.filter-loader {
  width: 15vh;
  margin-top: 20px;
}

.send-loader {
  height: 25px;
  margin-top: 8px;
  margin-right: 30px;
}

.manager-button {
  width: 100%;
  height: 38px;
  padding: 6px 23px;
  background: #558b2f;
  border-radius: 5px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    background: #33691e;
    cursor: pointer;
  }
}

.manager-expand {
  margin-top: 10px;

  .manager-expand-title {
    font-size: 18px;
    color: #7cb342;
    padding-bottom: 10px;
  }
}
