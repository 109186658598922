.popup-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95vh;
  overflow-y: auto;
   
  
    .TagImage{
      width: 90%;
      text-align: center;  
      margin: 15px; 
    }
}
.image-rotate-left{
  position: absolute;
  left: -50px;
  top: 300px;
  cursor: pointer;
}


.image-rotate-right{
  position: absolute;
  right: -50px;
  top: 300px;
  cursor: pointer;
}
#tag-img{
  height: 95vh;
  overflow-y: scroll;
}