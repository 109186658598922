.container-root{
    margin-top: 20px;

    .uploading {
        display: flex;
    
        .uploading-text {
          color: #7cb342;
          font-weight: 500;
          padding: 10px;
          margin-top: -8px;
        }
        .uploading-icon {
          margin-left: 8px;
        }
      }
}
.h1{
  color: #fff;
  font-size: 80px;
  margin: 0;
}
.h3{
  color: #464444;
  font-size: 34px;
  margin: 0;
}
.h4{
  margin: 0;
  color: #fff;
  font-size: 40px;
}
.h5{
  margin: 0;
  color: #fff;
  font-size: 24px;
}
// .error-wall {
// background-color: #d9bf77;
// width: 100%;
// height: 100%;
// margin: 0;
// padding: 0;;
// }
