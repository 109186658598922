.questions-paper {
  margin-top: 50px;
  padding: 40px;

  textarea {
    width: 100%;
    padding-left: 10px;
    border-radius: 4px;
    border: 0.7px solid #cfd8dc;
    min-height: 80px;
  }
}
